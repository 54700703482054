import { JitsiMeeting } from "@jitsi/react-sdk";
import { fetchSessionAction } from "actions/sessions";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import QuizVideoCall from "../quiz/QuizVideoCall";
import { startVideoQuizAction } from "actions/quizs";

function VideoMeeting() {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to leave?";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const { session } = useSelector((state) => state.packs);
  const { user, loading } = useSelector((state) => state.auth);
  const user_id = user.quiz_id;
  const [showQuiz, setShowQuiz] = useState(false);
  const [showPassQuiz, setShowPassQuiz] = useState(false);

  const [quizId, setQuizId] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const handleCloseQuiz = () => setShowQuiz(false);
  const handleClosePassQuiz = () => setShowPassQuiz(false);
  useEffect(() => {
    dispatch(fetchSessionAction(id));
  }, [dispatch, id]);

  const handleMeetingEnd = () => {
    navigate(
      `/instructor/dashboard/${session.book_id}?session_id=${session.id}`
    );
  };
  // Jistsi Meeting event listener

  const [api, setApi] = useState(null);
  useEffect(() => {
    if (api) {
      api.addEventListener("endpointTextMessageReceived", (event) => {
        setQuizId(event.data.eventData.text.data.quiz_id);
        setShowQuiz(true);
      });
    } else {
      console.log("API is not ready yet");
    }
  }, [api]);
  const handleStartQuiz = () => {
    dispatch(
      startVideoQuizAction({
        quiz_id: quizId,
        user_id,
        setShowPassQuiz,
        setShowQuiz,
      })
    );
  };

  return (
    <div>
      <Modal show={showQuiz} onHide={() => handleCloseQuiz()}>
        <Modal.Header style={{ backgroundColor: "#141414" }} closeButton>
          <Modal.Title style={{ color: "white" }}>Start Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#141414",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{
                backgroundColor: "#246FE5",
                borderColor: "#246FE5",
                margin: "auto",
              }}
              type="submit"
              variant="primary"
              id="add-new-event-btn"
              onClick={handleStartQuiz}
              disabled={loading}
            >
              Start
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <JitsiMeeting
        domain={"meeting.intellect.tn"}
        roomName={session.name.replace(/\s+/g, "_")}
        jwt={token}
        configOverwrite={{
          startWithAudioMuted: true,
          disablePolls: true,
          disableModeratorIndicator: true,
          startScreenSharing: true,
          enableEmailInStats: false,
          startWithVideoMuted: true,
          requireDisplayName: true,
          prejoinPageEnabled: true,
          disableInviteFunctions: true,
          enablePollsModeration: true,
          defaultLanguage: "en",
          enableUserRolesBasedOnToken: true,
          breakoutRooms: {
            enabled: true,
          },
          // Disable mobile app promotion
          disableDeepLinking: true,
          mobileAppPromo: false,
          disableMobileAppPreload: true,
          // Additional mobile-related settings
          p2p: {
            enabled: true,
          },
        }}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
          MOBILE_APP_PROMO: false,
          VIDEO_LAYOUT_FIT: "nocrop",
          // Disable all app download related features
          MOBILE_APP_ENABLED: false,
          NATIVE_APP_NAME: "",
          SHOW_MOBILE_APP_PROMO: false,
          SHOW_DEEP_LINKING_IMAGE: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = "91vh";
        }}
        onApiReady={(api) => {
          setApi(api);
        }}
        onReadyToClose={handleMeetingEnd}
      />

      <Modal show={showPassQuiz} onHide={() => handleClosePassQuiz()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuizVideoCall quiz_id={quizId} close={handleClosePassQuiz} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default VideoMeeting;
