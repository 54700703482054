// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// import layouts
import NavbarDefault from "layouts/marketing/navbars/NavbarDefault";
import { useSelector } from "react-redux";
import FooterWithLinks from "./footers/FooterWithLinks";
import NavbarReader from "./navbars/NavbarReader";

const NavbarLayout = (props) => {
  // const is_authenticated = true;
  const { is_authenticated } = useSelector((state) => state.auth);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage")
  );

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem("selectedLanguage"));
  }, [selectedLanguage]);
  const isArabic = selectedLanguage === "ar";
  return (
    <Fragment>
      {isArabic ? (
        <NavbarReader
          setSelectedLanguage={setSelectedLanguage}
          login={is_authenticated}
        />
      ) : (
        <NavbarDefault
          login={is_authenticated}
          setSelectedLanguage={setSelectedLanguage}
        />
      )}
      <main>
        {props.children}
        <Outlet />
      </main>
    </Fragment>
  );
};

export default NavbarLayout;
