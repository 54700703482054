// import node module libraries
import { submitAnswerAction, submitVideoAnswerAction } from "actions/quizs";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
//translate component
import { useTranslation } from "react-i18next";

const PaginationVedio = ({
  nPages,
  currentPage,
  setCurrentPage,
  selectedAnswer,
  currentQuestionId,
  setSelectedAnswer,
  quizId,
  close,
}) => {
  const { user, loading } = useSelector((state) => state.auth);
  const user_id = user.quiz_id;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleSubmitQuiz = async () => {
    const requestBody = {
      answer: selectedAnswer,
      question_id: currentQuestionId,
      user_id: user_id,
      quiz_id: quizId,
      last: currentPage === nPages ? true : false,
    };

    try {
      dispatch(
        submitVideoAnswerAction({
          requestBody,
          setSelectedAnswer,
          nextPage,
          close,
        })
      );
    } catch (error) {
      console.error("Error submitting answer:", error);
    }
  };

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div>
      <h5>
        <span style={{ color: "red" }}>{t("notice")}</span> {t("if_you_dont")}
      </h5>

      <div
        className={`d-flex justify-content-${
          currentPage > 1 ? "between" : "end"
        }`}
        style={{ marginTop: "20px" }}
      >
        {currentPage > 1 && (
          <Button variant="secondary" onClick={prevPage}>
            <i className="fe fe-arrow-left"></i> {t("previous")}
          </Button>
        )}
        {currentPage === nPages ? (
          <Link className="btn btn-primary" onClick={() => handleSubmitQuiz()}>
            {t("finish")}
          </Link>
        ) : (
          <Button
            variant="primary"
            disabled={loading}
            onClick={() => handleSubmitQuiz()}
          >
            {t("next")} <i className="fe fe-arrow-right"></i>
          </Button>
        )}
      </div>
    </div>
  );
};

export default PaginationVedio;
