import React, { useEffect, useState } from "react";

const SentenceScramble = ({
  questionData,
  setSelectedAnswer,
  selectedAnswer,
  disabled,
}) => {
  useEffect(() => {
    setSelectedAnswer([]);
  }, [setSelectedAnswer]);

  const shuffleArray = (array) => {
    return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  };

  const [initialWords, setInitialWords] = useState(
    shuffleArray(questionData.data)
  );

  useEffect(() => {
    setInitialWords(shuffleArray(questionData.data));
  }, [questionData]);
  const [wordPositions, setWordPositions] = useState({}); // Track the original positions of words

  // Function to handle the click event for words
  const handleWordClick = (word, type) => {
    if (disabled) return; // Prevent interaction if disabled

    if (type === "list") {
      // Save the word's original position when moving it to the sentence
      const wordIndex = initialWords.indexOf(word);
      setWordPositions({
        ...wordPositions,
        [word]: wordIndex, // Store the position of the word
      });

      // Move the word to the sentence
      setInitialWords(initialWords.filter((w) => w !== word));
      setSelectedAnswer([...selectedAnswer, word]);
    } else if (type === "sentence") {
      // Move the word back to the list at its original position
      const originalIndex = wordPositions[word];

      // Remove word from the sentence
      setSelectedAnswer(selectedAnswer?.filter((w) => w !== word));

      // Re-insert the word into the list at its original position
      const updatedList = [...initialWords];
      updatedList.splice(originalIndex, 0, word); // Insert at the original position

      setInitialWords(updatedList);
    }
  };

  return (
    <div>
      {/* Answer Sentence */}
      <div style={{ marginBottom: "20px" }}>
        <h4>Your Sentence</h4>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {selectedAnswer
            ? selectedAnswer?.map((word, index) => (
                <div
                  key={index}
                  onClick={() => handleWordClick(word, "sentence")}
                  style={{
                    cursor: disabled ? "not-allowed" : "pointer",
                    padding: "8px 15px",
                    background: disabled ? "#f8d7da" : "#d1e7dd",
                    margin: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {word}
                </div>
              ))
            : ""}
        </div>
      </div>
      {/* Word List */}
      <div style={{ marginBottom: "20px" }}>
        <h4>Available Words</h4>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {initialWords.map((word, index) => (
            <div
              key={index}
              onClick={() => handleWordClick(word, "list")}
              style={{
                cursor: disabled ? "not-allowed" : "pointer",
                padding: "8px 15px",
                background: disabled ? "#f8d7da" : "lightgray",
                margin: "5px",
                borderRadius: "5px",
                opacity: disabled ? 0.5 : 1,
              }}
            >
              {word}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SentenceScramble;
