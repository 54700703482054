import { getDefinetion } from "api";
import { LOADING } from "./constants";

export const getDefinetionAction = (word) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getDefinetion(word);
   
    dispatch({ type: LOADING, payload: false });
    return data;
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    throw error?.response.data.message;
  }
};
