// import node module libraries
import React, { useMemo } from "react";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Card, Row, Col, Image, Table, Button } from "react-bootstrap";

// import profile layout wrapper
import ProfileLayout from "components/marketing/student/ProfileLayout";
// import data files
import { games } from "data/courses/MyCoursesData";
import { Link, useNavigate, useParams } from "react-router-dom";
// import utility file

const MyCourses = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const columns = useMemo(
    () => [
      {
        accessor: "image",
        Header: "Game",
        Cell: ({ value, row }) => {
          return (
            <div className="d-lg-flex align-items-center  text-center">
              <div>
                <Image src={value} alt="" className="rounded img-4by3-lg" />
              </div>
              <div className="ms-lg-3 mt-2 mt-lg-0">
                <h4 className="mb-1 h5">{row.original.title}</h4>
              </div>
            </div>
          );
        },
      },

      {
        accessor: "id",
        Header: "Start",
        Cell: ({ value, row }) => {
          return (
            <div className="d-lg-flex">
              <Button onClick={() => navigate(`/games/word-search/${id}`)}>
                Play
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => games, []);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: 6,
          hiddenColumns: columns.map((column) => {
            if (column.show === false) return column.accessor || column.id;
            else return false;
          }),
        },
      },
      useFilters,
      useGlobalFilter,
      usePagination
    );

  return (
    <ProfileLayout>
      <Card className="border-0">
        <Card.Header>
          <div className="mb-3 mb-lg-0">
            <h3 className="mb-0">Games</h3>
            <p className="mb-0">
              Explore fun and educational games designed to make learning
              exciting and interactive! Dive into challenges that boost
              creativity, critical thinking, and problem-solving skills while
              having a great time.
            </p>
          </div>
        </Card.Header>
        <Card.Body></Card.Body>
        <Card.Body className="p-0 pb-5">
          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Table
                hover
                responsive
                {...getTableProps()}
                className="text-nowrap table-centered"
              >
                <thead className="table-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table> */}
              <Link
                to={`/games/${id}`}
                // target="_blank"
              >
                <Button>Play</Button>
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </ProfileLayout>
  );
};

export default MyCourses;
