import { enc, HmacSHA256 } from "crypto-js";
/**
 * Functions in utils
 */

/**
 * Add commas to a number
 * v1.0.0
 */
export const numberWithCommas = (x, decimal = 0) => {
  return x.toLocaleString("en-US", { minimumFractionDigits: decimal });
};

/**
 * Get the file extension from given file name
 * v1.2.0
 */
export const getFileExtension = (filename) => {
  const extension = filename.split(".").pop();
  return extension;
};

/**
 * Get the random number between min and max value
 * v1.2.0
 */
export const getRandomNo = (min = 0, max = 100) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Get the color name/value based on given status
 * v1.2.0
 */
export const getStatusColor = (itemstatus) => {
  let color = "";
  switch (itemstatus) {
    case "In Progress":
      color = "info";
      break;
    case "Pending":
      color = "warning";
      break;
    case "Finished":
      color = "success";
      break;
    case "Cancel":
      color = "danger";
      break;
    default:
      color = "primary";
  }
  return color;
};

/**
 * Get the color name/value based on given status
 * v1.2.0
 */
export const getCategoryColor = (category) => {
  let color = "";
  switch (category) {
    case "Saas Services":
    case "Entertainment":
    case "Extra":
      color = "info";
      break;
    case "Design":
      color = "warning";
      break;
    case "Marketing":
      color = "success";
      break;
    case "Development":
      color = "danger";
      break;
    case "SEO":
      color = "primary";
      break;
    default:
      color = "primary";
  }
  return color;
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

// function to get time value in hh:mm AM | PM format
export const getTimeValue = (date) => {
  var hours = date.getHours();
  var day = date.getUTCDate();
  let month = date.getUTCMonth() + 1;
  let year = date.getUTCFullYear();

  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime =
    day + "/" + month + "/" + year + " " + hours + ":" + minutes + " " + ampm;
  return strTime;
};
export const reformatSearchDate = (isoDate) => {
  const date = new Date(isoDate);

  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");

  return `${dd}-${mm}-${yyyy}`;
};
export const reformatSearchDateTime = (isoDateTime) => {
  const dateTime = new Date(isoDateTime);

  const yyyy = dateTime.getFullYear();
  const mm = String(dateTime.getMonth() + 1).padStart(2, "0");
  const dd = String(dateTime.getDate()).padStart(2, "0");
  const hh = String(dateTime.getHours()).padStart(2, "0");
  const min = String(dateTime.getMinutes()).padStart(2, "0");

  return `${dd}-${mm}-${yyyy} ${hh}:${min}`;
};
export const reformatDate = (originalDate) => {
  const parts = originalDate.split("/");
  const dd = parts[0];
  const mm = parts[1];
  const yyyy = parts[2];

  return `${dd}-${mm}-${yyyy}`;
};
// function to get date value in Month Name DD, YYYY format
export const getDateValue = (date) => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const yyyy = date.getFullYear();
  let mm = date.getMonth();
  let dd = date.getDate();
  var today = month[mm] + " " + dd + ", " + yyyy;
  return today;
};

// function to generate slug or ID with slug format
export const getSlug = (text) => {
  text = text.toLowerCase();
  text = text.replace(/ /g, "-").replace(/[^\w-]+/g, "");
  return text;
};
// get public id
const regex = /\/v\d+\/([^/]+)\.\w{3,4}$/;
export const getPublicIdFromUrl = (url) => {
  const match = url.match(regex);
  return match ? match[1] : null;
};

const CLOUDINARY_API_SECRET = "jSW_GSXsGujJUSwWP7rjlCIV4TU";
export const generateCloudinarySignature = (params) => {
  const signaturePayload = Object.keys(params)
    .sort()
    .map((key) => key + "=" + params[key])
    .join("&");

  const signature = HmacSHA256(
    signaturePayload,
    CLOUDINARY_API_SECRET
  ).toString(enc.Hex);

  return signature;
};
export // Function to generate HTML for different question types
function generateQuestionHTML(question) {
  let html = "";

  switch (question.type) {
    case "checkbox":
      question.data.forEach((item) => {
        if (item.correct === "true") {
          html += `<p>The correct answer is: ${item.content}</p>`;
        }
      });
      break;

    case "multi-choice":
      question.data.forEach((item) => {
        if (item.correct === "true") {
          html += `<p>The correct answer is: ${item.content}</p>`;
        }
      });
      break;

    case "true-false":
      html += `<p>The correct answer is: ${
        question.data.correct === "true" ? "True" : "False"
      }</p>`;
      break;

    case "matching":
      html += "<h2>The correct match is:</h2>";
      question.data.forEach((pair) => {
        html += `<p> ${pair.left} - ${pair.right}</p>`;
      });
      break;

    case "fill-in-the-blank":
      const textParts = question.data.text.split("###");
      const filledText = textParts.reduce((result, part, index) => {
        if (index < question.data.blanks.length) {
          return (
            result +
            " " +
            part +
            " " +
            `<strong>${question.data.blanks[index]}</strong>`
          );
        }
        return result + part;
      }, "");
      html += `<p>The complete text is: ${filledText}</p>`;
      break;

    case "order-the-phrase":
      html += "<p>The correct phrase order is:</p><ul>";
      question.data.forEach((word, index) => {
        html += `<li>Word ${index + 1}: ${word}</li>`;
      });
      html += "</ul>";
      break;

    default:
      html += "<p>Unknown question type</p>";
  }

  return html;
}
export function getRandomIds(ids, n) {
  if (n > ids.length) {
    return ids;
  }

  const shuffled = [...ids].sort(() => 0.5 - Math.random()); // Shuffle the array
  return shuffled.slice(0, n); // Take the first n elements
}

export function generateWordSearch(words, directionsInput) {
  // Step 1: Convert all words to uppercase
  words = words.map((word) => word.toUpperCase());

  // Step 2: Determine grid size
  const maxWordLength = Math.max(...words.map((word) => word.length));
  const gridSize = Math.floor(Math.random() * 2) + maxWordLength; // Random size between maxWordLength and maxWordLength + 1

  // Step 3: Initialize a square grid
  const grid = Array.from({ length: gridSize }, () =>
    Array(gridSize).fill("-")
  );

  // Step 4: Define all possible directions
  const allDirections = {
    right: [0, 1], // → Right
    left: [0, -1], // ← Left
    down: [1, 0], // ↓ Down
    up: [-1, 0], // ↑ Up
    diagonalDownRight: [1, 1], // ↘ Diagonal Down-Right
    diagonalDownLeft: [1, -1], // ↙ Diagonal Down-Left
    diagonalUpRight: [-1, 1], // ↗ Diagonal Up-Right
    diagonalUpLeft: [-1, -1], // ↖ Diagonal Up-Left
  };

  // Filter user-selected directions
  const directions = directionsInput
    .map((dir) => allDirections[dir])
    .filter(Boolean);

  if (directions.length === 0) {
    throw new Error(
      "Invalid directions input. Please provide valid directions."
    );
  }

  const placedWords = [];

  // Step 5: Utility functions
  function canPlace(word, x, y, dx, dy) {
    for (let i = 0; i < word.length; i++) {
      const nx = x + i * dx;
      const ny = y + i * dy;
      if (
        nx < 0 ||
        ny < 0 ||
        nx >= gridSize ||
        ny >= gridSize ||
        (grid[nx][ny] !== "-" && grid[nx][ny] !== word[i])
      ) {
        return false;
      }
    }
    return true;
  }

  function placeWord(word, x, y, dx, dy) {
    for (let i = 0; i < word.length; i++) {
      const nx = x + i * dx;
      const ny = y + i * dy;
      grid[nx][ny] = word[i];
    }
    placedWords.push(word);
  }

  // Step 6: Greedy word placement
  words.sort((a, b) => b.length - a.length); // Sort words by descending length
  for (const word of words) {
    let placed = false;
    for (let attempts = 0; attempts < 50; attempts++) {
      const x = Math.floor(Math.random() * gridSize);
      const y = Math.floor(Math.random() * gridSize);
      const [dx, dy] =
        directions[Math.floor(Math.random() * directions.length)];
      if (canPlace(word, x, y, dx, dy)) {
        placeWord(word, x, y, dx, dy);
        placed = true;
        break;
      }
    }
    if (!placed) {
      console.warn(`Could not place word: ${word}`);
    }
  }

  // Step 7: Fill empty spaces with random uppercase letters
  for (let i = 0; i < gridSize; i++) {
    for (let j = 0; j < gridSize; j++) {
      if (grid[i][j] === "-") {
        grid[i][j] = String.fromCharCode(65 + Math.floor(Math.random() * 26)); // Random uppercase letter A-Z
      }
    }
  }

  // Step 8: Format the output
  const wordSearchData = {
    question: "Find the hidden words!",
    gridSize: gridSize,
    letters: grid,
    hiddenWords: placedWords,
  };

  return wordSearchData;
}
export function cleanTextAndFilterAuxiliaryWords(text) {
  // List of auxiliary words to exclude
  const auxiliaryWords = new Set([
    "he",
    "she",
    "is",
    "am",
    "are",
    "was",
    "were",
    "it",
    "they",
    "you",
    "we",
    "a",
    "an",
    "the",
    "and",
    "or",
    "but",
    "to",
    "in",
    "on",
    "at",
    "of",
    "for",
    "with",
    "as",
    "by",
    "be",
    "has",
    "have",
    "had",
    "that",
    "this",
    "those",
  ]);

  // Remove punctuation (commas, periods, question marks, etc.)
  const cleanedText = text.replace(/[.,!?;:]/g, "").toLowerCase();

  // Split text into words
  const words = cleanedText.split(/\s+/);

  // Select 4 random words from the list of words
  const randomWords = [];
  const wordSet = new Set();
  while (wordSet.size < 4 && wordSet.size < words.length) {
    const randomIndex = Math.floor(Math.random() * words.length);
    wordSet.add(words[randomIndex]);
  }
  randomWords.push(...wordSet);

  // Filter out auxiliary words
  const filteredWords = words.filter(
    (word) => word && !auxiliaryWords.has(word)
  );

  return { randomWords, filteredWords };
}

const utils = [
  numberWithCommas,
  getFileExtension,
  getRandomNo,
  getStatusColor,
  chunk,
  getTimeValue,
  getDateValue,
  getSlug,
];

export default utils;
