// import node module libraries
import React from "react";
import { Card, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// import data files

// import profile layout wrapper
import ProfileLayout from "components/marketing/student/ProfileLayout";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createQuizAction } from "actions/quizs";
import { getRandomIds } from "helper/utils";

const Training = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const { book } = useSelector((state) => state.books);
  const { user } = useSelector((state) => state.auth);
  const searchParams = new URLSearchParams(location.search);
  const pack_id = searchParams.get("pack_id");

  const user_id = user.quiz_id;
  const { storyQuestions } = useSelector((state) => state.quizs);
  const selectedQuestions = getRandomIds(storyQuestions, 7);
  const myQuiz = {
    title: pack_id == 99 ? book.title + " Test" : book.title + " training",
    pass_mark: 50,
    duration: 30,
    questions: pack_id == 99 ? storyQuestions : selectedQuestions,
    withAnswers: true,
    training: true,
  };
  const handleGenerateQuiz = () => {
    dispatch(createQuizAction(myQuiz, navigate, id, user_id));
  };
  return (
    <ProfileLayout>
      <Card className="border-0">
        <Card.Header>
          <div className="mb-3 mb-lg-0">
            <h3 className="mb-0">Training</h3>
            <p className="mb-0">
              Test your knowledge and sharpen your skills with our Quiz Training
              section. Interactive and engaging quizzes designed to help you
              learn and grow in a fun way!
            </p>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {/* Table */}
          <div className="border-0 d-flex justify-content-center pt-5  ">
            {selectedQuestions.length < 7 && (
              <span style={{ color: "red", margin: "auto" }}>
                No training is available yet.
              </span>
            )}
          </div>
          <div className="border-0 d-flex justify-content-center p-10  ">
            <Button
              disabled={selectedQuestions.length < 7}
              onClick={handleGenerateQuiz}
            >
              Start
            </Button>
          </div>
        </Card.Body>
      </Card>
    </ProfileLayout>
  );
};

export default Training;
