import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllQuizs,
  firstQuestion,
  startQuiz,
  submitAnswer,
  getResult,
  getQuizsInSession,
  getQuizByToken,
  getQuiz,
  getQuizAnswer,
  fetchAllResultsFromOneReader,
  readerDashboard,
  fetchUserDetails,
  getQuestionByCat,
  createQuiz,
} from "api";
import {
  GET_ALL_QUIZS,
  LOADING,
  GET_FIRST_QUESTION,
  GET_RESULT,
  GET_QUIZS_IN_SESSION,
  GET_RESULT_IN_SESSION,
  GET_QUIZ_BY_TOKEN,
  GET_QUIZ,
  GET_ALL_RESULT_FOR_ONE_READER,
  GET_USER_DETAILS,
  GET_READER_RESULT,
  GET_ANSWER,
  QUESTION_ANSWER,
  QUESTION,
} from "./constants";
import { generateQuestionHTML } from "helper/utils";

export const fetchAllQuizsAction = (user_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getAllQuizs(user_id);
    dispatch({ type: GET_ALL_QUIZS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const fetchResult = (token, user_id, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getResult(token, user_id);
    dispatch({ type: GET_RESULT, payload: data.final_results });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      const errorMessage = error.response.data.message;
      console.log(errorMessage);
      if (
        errorMessage ===
        "The results will be shown after teacher validation,please wait for the validation"
      ) {
        navigate(`/quiz/wait_validation`);
      }
    }
  }
};
export const fetchUserDetailsAction = (user_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await fetchUserDetails(user_id);
    dispatch({ type: GET_USER_DETAILS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const startVideoQuizAction =
  ({ quiz_id, user_id, setShowPassQuiz, setShowQuiz }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const data = await startQuiz(quiz_id, user_id);
      dispatch({ type: GET_FIRST_QUESTION, payload: data });
      setShowPassQuiz(true);
      setShowQuiz(false);
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message;
      if (errorMessage) {
        console.log(errorMessage);
        toast.error(errorMessage);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const startQuizAction =
  (quiz_id, user_id, session_id, book_id, navigate) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const data = await startQuiz(quiz_id, user_id);
      navigate(
        `/quiz/quiz_pass?session_id=${session_id}&book_id=${book_id}&quiz_id=${quiz_id}&user_id=${user_id}`
      );
      dispatch({ type: GET_FIRST_QUESTION, payload: data });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message;
      if (errorMessage) {
        console.log(errorMessage);
        toast.error(errorMessage);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

export const getQuizAction = (quiz_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getQuiz(quiz_id);

    dispatch({ type: GET_QUIZ, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }

    dispatch({ type: LOADING, payload: false });
  }
};

export const submitAnswerAction =
  ({
    requestBody,
    setSelectedAnswer,
    nextPage,
    linkToResult,
    navigate,
    withAnswer,
    training,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await submitAnswer(requestBody);
      console.log(data);
      if (data.answer.success && withAnswer) {
        dispatch({ type: GET_ANSWER, payload: true });
      } else if (withAnswer) {
        dispatch({ type: GET_ANSWER, payload: false });
        dispatch({
          type: QUESTION_ANSWER,
          payload: generateQuestionHTML(data.question),
        });
      }
      setSelectedAnswer(null);
      if (requestBody.last && !withAnswer) {
        navigate(linkToResult);
      } else if (!withAnswer && !requestBody.last) {
        nextPage();
      }
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING, payload: false });
      const errorMessage = error?.response?.data?.message;

      if (
        (errorMessage && errorMessage == "Time's Up") ||
        errorMessage == "Quiz already completed"
      ) {
        navigate(`/student/student-books/`);
      }
    }
  };
export const submitVideoAnswerAction =
  ({ requestBody, setSelectedAnswer, nextPage, close }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      await submitAnswer(requestBody);

      setSelectedAnswer(null);
      if (requestBody.last) {
        close();
      } else {
        nextPage();
      }
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING, payload: false });
    }
  };
export const getQuizAnswerAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getQuizAnswer(formData);

    dispatch({ type: GET_RESULT, payload: data });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }

    dispatch({ type: LOADING, payload: false });
  }
};
export const getQuizInSessionAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getQuizsInSession(formData);

    if (data.quizes.length > 0) {
      const quizData = await Promise.all(
        data.quizes.map(async (e) => {
          const { data: quiz } = await getQuizByToken({ token: e.quiz_token });

          if (quiz) {
            return { ...quiz, release_date: e.release_date };
          }
        })
      );

      dispatch({ type: GET_QUIZS_IN_SESSION, payload: quizData });
    } else {
      dispatch({ type: GET_QUIZS_IN_SESSION, payload: [] });
    }

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const getResultInSessionAction =
  ({ session_id, user_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await getQuizsInSession({ session_id });
      if (data.quizes.length > 0) {
        const quizData = await Promise.all(
          data.quizes.map(async (e) => {
            const id = e.quiz_token;
            const { data: quiz } = await getQuiz(id);

            if (quiz) {
              try {
                const result = await getQuizAnswer({
                  quiz: quiz._id,
                  user: user_id,
                });
                if (result) {
                  return {
                    ...quiz,
                    final_results: result.data,
                    release_date: e.release_date,
                  };
                }
              } catch (error) {
                return {
                  ...quiz,
                  final_results: "incomplete",
                  release_date: e.release_date,
                };
              }
            }
          })
        );

        dispatch({ type: GET_RESULT_IN_SESSION, payload: quizData });
      } else {
        dispatch({ type: GET_RESULT_IN_SESSION, payload: [] });
      }

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        console.error(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

export const QuizByTokenAction = (token) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getQuizByToken({ token });

    dispatch({ type: GET_QUIZ_BY_TOKEN, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }

    dispatch({ type: LOADING, payload: false });
  }
};

export const fetchAllResultsFromOneReaderAction =
  (user_id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await fetchAllResultsFromOneReader(user_id);
      dispatch({ type: GET_READER_RESULT, payload: data.userAnswers });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

const fetchBookWithQuizAction = async (session_id, user_id) => {
  try {
    const { data } = await getQuizsInSession({ session_id });
    if (data.quizes.length > 0) {
      var quizData = [];
      await Promise.all(
        data.quizes.map(async (e) => {
          const id = e.quiz_token;
          const { data: quiz } = await getQuiz(id);
          if (quiz) {
            try {
              const result = await getQuizAnswer({
                quiz: quiz._id,
                user: user_id,
              });
              if (result.data) {
                quizData.push({
                  score: result.data.score,
                  max_score: result.data.max_score,
                  percentage: Math.round(result.data.percentage),
                  success: result.data.success,
                  completed: result.data.completed,
                  quiz_title: result.data.quiz.title,
                });
              }
            } catch (error) {
              console.log(error);
            }
          }
        })
      );
      const sumScore = quizData.reduce((acc, quiz) => acc + quiz.score, 0);
      const sumMaxScore = quizData.reduce(
        (acc, quiz) => acc + quiz.max_score,
        0
      );
      const sumPercentage = Math.round(
        quizData.reduce((acc, quiz) => acc + quiz.percentage, 0) /
          quizData.length
      );
      return {
        sumScore,
        sumMaxScore,
        sumPercentage,
        quizData,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const BookwithResultAction = (user_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await readerDashboard();

    const resultData = await Promise.all(
      data?.followed_sessions?.map(async (e) => {
        const sessionData = await fetchBookWithQuizAction(e.id, user_id);
        if (sessionData !== undefined) {
          return {
            passed: true,
            book_img: e.book_img,
            book_name: e.book_title,
            book_id: e.book_id,
            session_name: e.session_name,
            approved: e.approved,
            author: e.author,
            score: sessionData.sumScore,
            max_score: sessionData.sumMaxScore,
            percentage: sessionData.sumPercentage,
            quizData: sessionData.quizData,
            id: e.id,
          };
        } else {
          return {
            passed: false,
            book_img: e.book_img,
            book_name: e.book_title,
            book_id: e.book_id,
            session_name: e.session_name,
            approved: e.approved,
            author: e.author,
            score: 0,
            max_score: 0,
            percentage: 0,
            id: e.id,
          };
        }
      })
    );
    dispatch({ type: GET_ALL_RESULT_FOR_ONE_READER, payload: resultData });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const getQuestionByCatAction = (category) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getQuestionByCat(category);

    dispatch({ type: QUESTION, payload: data.questions });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const createQuizAction =
  (fromData, navigate, book_id, user_id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await createQuiz(fromData);

      dispatch(startQuizAction(data._id, user_id, null, book_id, navigate));

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
