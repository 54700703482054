import { fetchOneBook } from "actions/books";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Games = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { book } = useSelector((state) => state.books);
  console.log(book?.title);
  useEffect(() => {
    dispatch(fetchOneBook(id));
  }, []);
  const competition = book?.title?.includes("##C##") ? "true" : "false";
  return (
    <div className="games-container">
      <iframe
        style={{ height: "92vh" }}
        src={`https://games.iread.education/?id=${id}&competition=${competition}`}
        title="iRead Games"
        width="100%"
        // height="100%"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};

export default Games;
